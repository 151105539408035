import classnames from "classnames";
import { FC } from "react";

import styles from "./textContainer.module.scss";

interface ITextContainer {
  timeLine: gsap.core.Timeline;
}
const TextContainer: FC<ITextContainer> = (
  props: ITextContainer
): JSX.Element => {
  return (
    <section id={"textContainer"} className={styles.textContainer}>
      <div id={"text1"} className={styles.text}>
        <div>UNCERTAINTY AMID UNPRECEDENTED TIMES</div>
      </div>
      <div id={"text2"} className={styles.text}>
        <div>FILLED WITH ALARM AND APPREHENSION</div>
      </div>
      <div id={"text3"} className={styles.text}>
        <div>BUT FRICTION CREATES A SPARK</div>
      </div>
      <div id={"text4"} className={styles.text}>
        <div>SPARKS FUEL IDEAS</div>
      </div>
      <div id={"text5"} className={styles.text}>
        <div>AND AN IDEA CAN BURN BRIGHT</div>
      </div>
      <div id={"text6"} className={styles.text}>
        <div>WE'RE HERE TO STOKE THE FLAMES</div>
      </div>
      <div id={"text7"} className={styles.text}>
        <div>AND WATCH THOSE IDEAS IGNITE</div>
      </div>
      <div id={"text8"} className={styles.text}>
        <div>IN THE NEW YEAR AND MANY MORE TO COME</div>
      </div>
      <div id={"text9"} className={classnames(styles.text, styles.lastText)}>
        <div>HAPPY NEW YEAR!</div>
      </div>
    </section>
  );
};

export default TextContainer;
