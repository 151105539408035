import gsap from "gsap";
// const frame_count = 10;
// const offset_value = 95;

interface IAnimationSettings {
  left: string | number;
  top: string | number;
  delay: number;
}
const getValues = (isMobile: boolean) => {
  return {
    frame_count: 10,
    offset_value: isMobile ? 65 : 95,
  };
};
export const fireworksGroup1Animation = (
  fireWorkTimeLine: gsap.core.Timeline,
  isMobile: boolean
) => {
  const { frame_count, offset_value } = getValues(isMobile);
  const groupSettings: IAnimationSettings[] = [
    { left: 0, top: 0, delay: 250 },
    { left: "25%", top: "30%", delay: 350 },
    { left: "75%", top: "35%", delay: 300 },
  ];

  const fireWorksScene = gsap.utils.toArray(".scene1");
  fireWorksScene.forEach((el: gsap.TweenTarget, index) => {
    fireWorkTimeLine.set(el, {
      left: groupSettings[index].left,
      top: groupSettings[index].top,
    });
  });
  const fireWorksViewer = gsap.utils.toArray("#viewer1");
  fireWorksViewer.forEach((el: gsap.TweenTarget, index) => {
    fireWorkTimeLine.to(el, {
      backgroundPosition: -offset_value * frame_count * 2 + "px 50%",
      ease: "steps(" + frame_count + ")",
      scrollTrigger: {
        trigger: ".scene1",
        start:
          "-=" +
          ((frame_count * offset_value) / 2 - groupSettings[index].delay),
        end: "+=" + frame_count * offset_value,
        scrub: true,
      },
    });
  });
};

export const fireworksGroup2Animation = (
  fireWorkTimeLine: gsap.core.Timeline,
  isMobile: boolean
) => {
  const { frame_count, offset_value } = getValues(isMobile);
  const groupSettings: IAnimationSettings[] = [
    { left: "-1%", top: "13%", delay: isMobile ? 100 : 50 },
    { left: "18%", top: "45%", delay: isMobile ? 200 : 200 },
    { left: "60%", top: "-20%", delay: isMobile ? 0 : 0 },
    { left: "80%", top: "25%", delay: isMobile ? 300 : 0 },
  ];

  const fireWorksScene = gsap.utils.toArray(".scene2");
  fireWorksScene.forEach((el: gsap.TweenTarget, index) => {
    fireWorkTimeLine.set(el, {
      left: groupSettings[index].left,
      top: groupSettings[index].top,
    });
  });
  const fireWorksViewer = gsap.utils.toArray("#viewer2");
  fireWorksViewer.forEach((el: gsap.TweenTarget, index) => {
    const delay = groupSettings[index].delay;

    const start = isMobile
      ? "-=" + ((frame_count * offset_value) / 8 + delay)
      : "-=" + ((frame_count * offset_value) / 2 - delay);

    const end = isMobile
      ? "+=" + frame_count * offset_value
      : "+=" + frame_count * offset_value;

    fireWorkTimeLine.to(el, {
      backgroundPosition: -offset_value * frame_count * 2 + "px 50%",
      ease: "steps(" + frame_count + ")",
      scrollTrigger: {
        trigger: ".scene2",
        start: start,
        end: end,
        endTrigger: "#pin11",
        scrub: true,
      },
    });
  });
};

export const fireworksGroup3Animation = (
  fireWorkTimeLine: gsap.core.Timeline,
  isMobile: boolean
) => {
  const { frame_count, offset_value } = getValues(isMobile);
  const groupSettings: IAnimationSettings[] = [
    { left: "0", top: "0", delay: 200 },
    { left: "-2%", top: "45%", delay: -350 },
    { left: "9%", top: "40%", delay: 150 },
    { left: "13%", top: "-10%", delay: -100 },
    { left: "25%", top: "40%", delay: -150 },
    { left: "50%", top: "-10%", delay: 100 },
    { left: "70%", top: "40%", delay: -300 },
    { left: "75%", top: "25%", delay: 150 },
  ];

  const fireWorksScene = gsap.utils.toArray(".scene3");
  fireWorksScene.forEach((el: gsap.TweenTarget, index) => {
    fireWorkTimeLine.set(el, {
      left: groupSettings[index].left,
      top: groupSettings[index].top,
    });
  });
  const fireWorksViewer = gsap.utils.toArray("#viewer3");
  fireWorksViewer.forEach((el: gsap.TweenTarget, index) => {
    fireWorkTimeLine.to(el, {
      backgroundPosition: -offset_value * frame_count * 2 + "px 50%",
      ease: "steps(" + frame_count + ")",
      scrollTrigger: {
        trigger: ".scene3",
        start:
          "-=" +
          ((frame_count * offset_value) / 2 + groupSettings[index].delay),
        end: "+=" + frame_count * offset_value,
        scrub: true,
      },
    });
  });
};
export const fireworksGroup4Animation = (
  fireWorkTimeLine: gsap.core.Timeline,
  isMobile: boolean
) => {
  const { frame_count, offset_value } = getValues(isMobile);
  const groupSettings: IAnimationSettings[] = [
    { left: "-5%", top: "0", delay: 300 },
    { left: "10%", top: "50%", delay: 0 },
    { left: "18%", top: "-25%", delay: 100 },
    { left: "30%", top: "35%", delay: 250 },
    { left: "50%", top: "35%", delay: -150 },
    { left: "60%", top: "-5%", delay: 250 },
    { left: "70%", top: "40%", delay: 0 },
    { left: "80%", top: "-20%", delay: 100 },
    { left: "82%", top: "10%", delay: -150 },
  ];

  const fireWorksScene = gsap.utils.toArray(".scene4");
  fireWorksScene.forEach((el: gsap.TweenTarget, index) => {
    fireWorkTimeLine.set(el, {
      left: groupSettings[index].left,
      top: groupSettings[index].top,
    });
  });
  const fireWorksViewer = gsap.utils.toArray("#viewer4");
  fireWorksViewer.forEach((el: gsap.TweenTarget, index) => {
    fireWorkTimeLine.to(el, {
      backgroundPosition: -offset_value * frame_count * 2 + "px 50%",
      ease: "steps(" + frame_count + ")",
      scrollTrigger: {
        trigger: ".scene4",
        start:
          "-=" +
          ((frame_count * offset_value) / 2 + groupSettings[index].delay),
        end: "+=" + frame_count * offset_value,
        scrub: true,
      },
    });
  });
};

export const fireworksGroup5Animation = (
  fireWorkTimeLine: gsap.core.Timeline,
  isMobile: boolean
) => {
  const { frame_count, offset_value } = getValues(isMobile);
  const groupSettings: IAnimationSettings[] = [
    { left: "0", top: "-20%", delay: 0 },
    { left: "5%", top: "30%", delay: 100 },
    { left: "30%", top: "35%", delay: -300 },
    { left: "55%", top: "50%", delay: 0 },
    { left: "65%", top: "-5%", delay: 0 },
    { left: "75%", top: "40%", delay: 100 },
  ];

  const fireWorksScene = gsap.utils.toArray(".scene5");
  fireWorksScene.forEach((el: gsap.TweenTarget, index) => {
    fireWorkTimeLine.set(el, {
      left: groupSettings[index].left,
      top: groupSettings[index].top,
    });
  });
  const fireWorksViewer = gsap.utils.toArray("#viewer5");
  fireWorksViewer.forEach((el: gsap.TweenTarget, index) => {
    const delay = groupSettings[index].delay;

    const start = isMobile
      ? "-=" + ((frame_count * offset_value) / 8 + delay)
      : "-=" + ((frame_count * offset_value) / 2 + delay);

    const end = isMobile
      ? "+=" + (frame_count * offset_value) / 2
      : "+=" + frame_count * offset_value;


    fireWorkTimeLine.to(el, {
      backgroundPosition: -offset_value * frame_count * 2 + "px 50%",
      ease: "steps(" + frame_count + ")",
      scrollTrigger: {
        trigger: ".scene5",
        start: start,
        end: end,
        scrub: true,
      },
    });
  });
};
