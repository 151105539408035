import './App.scss';

import classnames from 'classnames';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';

import {
  fireworksGroup1Animation,
  fireworksGroup2Animation,
  fireworksGroup3Animation,
  fireworksGroup4Animation,
  fireworksGroup5Animation,
} from './animations/fireworks';
import {
  scene10Animations,
  scene11Animations,
  scene12Animations,
  scene13Animations,
  scene14Animations,
  scene1Animations,
  scene2Animations,
  scene3Animations,
  scene4Animations,
  scene5To6Animations,
  scene7Animations,
  scene8Animations,
  scene9Animations,
} from './animations/scenes';
import styles from './app.module.scss';
import Scroller from './components/scroller/scroller';
import TextContainer from './components/textContainer/textContainer';
import cloudSource from './img/clouds.png';
import grad1 from './img/grad1.png';
import grad2 from './img/grad2.png';
import grad3 from './img/grad3.png';
import grad4 from './img/grad4.png';
import grad5 from './img/grad5.png';
import ideasToLifeGrad from './img/ideasToLifeGrad.png';
import increasedClouds from './img/increasedClouds.png';
import increasedCloudsGrad from './img/increasedCloudsGrad.png';
import initializedLogo from './img/Initialized_logo_black.png';
import cloudShiftSource from './img/onlyClouds.png';
import onlyCloudsGrad from './img/onlyCloudsGrad1.png';
import speckCloudsGrad from './img/speckCloudsGrad.png';
import utilStyles from './scss/utils.module.scss';
import { scrollToTop } from './utils';

function App() {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [currentScene, setCurrentScene] = useState<number>(1);
  const introContainer = "#introContainer";
  const intro = "#intro";
  const subIntro = "#subIntro";
  const glitch = "#glitch";
  const cloudContainer = "#cloudContainer";
  const cloudImg = "#cloudImg";

  const glitchCount = 70;
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {}, []);

  const isMobile = width <= 1024;
  const isLandscape = () =>
      window.matchMedia("(orientation:landscape)").matches,
    orientation = isLandscape() ? "landscape" : "portrait";

  const shouldShowRotate = isMobile && orientation === "portrait";

  // Timelines
  const [introTimeLine, setIntroTimeLine] = useState<gsap.core.Timeline>(null);
  const [cloudTimeLine, setCloudTimeLine] = useState<gsap.core.Timeline>(null);
  const [fireWorkTimeLine, setFireworkTimeLine] =
    useState<gsap.core.Timeline>(null);

  // initial load
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const reload = () => window.location.reload();
    setLoaded(true);
    // restarts if focus is reset
    window.addEventListener("focus", reload);
    scrollToTop();
    const resize = function () {
      if (window.innerWidth !== windowWidth) {
        window.location.reload();
        scrollToTop();
      }
    };
    let windowWidth = window.innerWidth;
    window.addEventListener("resize", resize);

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("focus", reload);
      window.removeEventListener("resize", resize);
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    // intro scene is complete
    if (introTimeLine && currentScene === 2) {
      // fade sub text
      introTimeLine.to(subIntro, { opacity: 0 });
      introTimeLine.to(introContainer, { opacity: 0 });
    }
  }, [currentScene, introTimeLine]);

  // First Scene with dots
  const onStart = useCallback(() => {
    cloudTimeLine.set("body", {
      overflow: "unset",
    });
    cloudTimeLine.set(cloudImg, {
      scaleX: 3.5,
      scaleY: 3.5,
      transformOrigin: "0, 0",
    });

    cloudTimeLine.to(cloudContainer, {
      opacity: 1,
      ease: "linear",
    });
  }, [cloudTimeLine]);

  useEffect(() => {
    if (currentScene === 2) {
      // start
      onStart();
      // Something about tough times
      scene1Animations(cloudTimeLine);
      // ALL THE CHALLENGES WE FACE
      scene2Animations(cloudTimeLine);
      // BUT
      scene3Animations(cloudTimeLine);
      // WE SEE IT AS AN EXCITING TIME
      scene4Animations(cloudTimeLine);
      // color intensify
      scene5To6Animations(cloudTimeLine);
      // LET'S GET READY FOR NEW IDEAS 1
      scene7Animations(cloudTimeLine);
      // LET'S GET READY FOR NEW IDEAS 2
      scene8Animations(cloudTimeLine);
      // WHILE GEARING UP 1
      scene9Animations(cloudTimeLine);
      // WHILE GEARING UP 2
      scene10Animations(cloudTimeLine);
      //  IDEAS TO LIFE 1
      scene11Animations(cloudTimeLine);
      scene12Animations(cloudTimeLine);
      scene13Animations(cloudTimeLine);
      // // happy new years
      scene14Animations(cloudTimeLine);

      cloudTimeLine.play();
    }
  }, [cloudTimeLine, currentScene, onStart]);

  const positionFireWorks = useCallback(() => {
    if (fireWorkTimeLine !== null && loaded) {
      fireworksGroup1Animation(fireWorkTimeLine, isMobile);
      fireworksGroup2Animation(fireWorkTimeLine, isMobile);
      fireworksGroup3Animation(fireWorkTimeLine, isMobile);
      fireworksGroup4Animation(fireWorkTimeLine, isMobile);
      fireworksGroup5Animation(fireWorkTimeLine, isMobile);
    }
  }, [fireWorkTimeLine, isMobile, loaded]);

  useEffect(() => {
    positionFireWorks();
    if (fireWorkTimeLine !== null) {
      fireWorkTimeLine.play();
    }
  }, [fireWorkTimeLine, positionFireWorks]);

  const makeGlitches = useCallback(() => {
    const glitches = gsap.utils.toArray(glitch);
    glitches.forEach((el: any, index: number) => {
      const x = { start: 0, end: window.innerWidth };
      const y = { start: 0, end: window.innerHeight };

      gsap.set(el, {
        x: `random(${x.start},${x.end})`,
        y: `random(${y.start}, ${y.end})`,
        opacity: 0,
      });
      gsap.to(el, {
        opacity: 1,
        delay: `random(0, 2)`,
        yoyo: true,
        repeat: _.random(1, 3),
        ease: "",
        onComplete: () => {
          // after the last glitch, switch to the next scene
          if (index === glitches.length - 1) {
            setCurrentScene(2);
          }
        },
      });
    });
  }, []);

  const getIntroTimeLine = useCallback((): gsap.core.Timeline => {
    return (
      gsap
        .timeline({ paused: true })
        .to(intro, { opacity: 1 })
        .to(intro, { opacity: 0 })
        // after subintro is complete, glitches appear
        .to(subIntro, {
          opacity: 1,
          onComplete: () => {
            makeGlitches();
          },
        })
    );
  }, [makeGlitches]);

  useEffect(() => {
    if (loaded) {
      setIntroTimeLine(getIntroTimeLine());
      setCloudTimeLine(gsap.timeline({ paused: true }));
      setFireworkTimeLine(gsap.timeline({ paused: true }));
    }
  }, [getIntroTimeLine, loaded]);

  useEffect(() => {
    // once all the elements have loaded, intro time line is set, start it.
    if (loaded && introTimeLine && currentScene === 1) {
      introTimeLine.play();
    }
  }, [currentScene, introTimeLine, loaded]);

  return (
    <div className={styles.container}>
      {getMainScene(glitchCount, shouldShowRotate)}
      <Scroller canStart={currentScene === 2} />
      <TextContainer timeLine={cloudTimeLine} />
      {getCloudImages()}
      {getPins()}
    </div>
  );
}

const createCircles = (number: number): JSX.Element => {
  let circle = (
    <div key={`glitch-${number}`} id={"glitch"} className={styles.circle} />
  );
  return circle;
};

const getMainScene = (glitchCount: number, shouldShowRotate: boolean) => {
  if (shouldShowRotate) {
    return (
      <div className={styles.rotateContainer}>
        <span>↺</span>
        <h2>PLEASE ROTATE YOUR DEVICE</h2>
      </div>
    );
  }
  return (
    <header
      id={"introContainer"}
      className={classnames(
        utilStyles.df,
        utilStyles.aic,
        utilStyles.jcc,
        utilStyles.fdc
      )}
    >
      <div id={"intro"} className={styles.intro}>
        It may seem..
      </div>
      <div
        id={"subIntro"}
        className={classnames(styles.subIntro, utilStyles.aic, utilStyles.jcc)}
      >
        LIKE THE WORLD IS CRAZY RIGHT NOW
      </div>
      <div id={"glitches"} className={styles.glitchContainer}>
        {[...Array(glitchCount)].map((glitch, index) => createCircles(index))}
      </div>
    </header>
  );
};
const getCloudImages = () => {
  return (
    <section id={"cloudContainer"} className={styles.cloudContainer}>
      <div
        id={"overlay1"}
        className={classnames(styles.overlay, styles.overlay1)}
      />

      <div id={"cloud"} className={styles.cloud}>
        <img
          id={"cloudImg"}
          src={cloudSource}
          className={styles.cloudImg}
          alt={"main clouds"}
          loading={"lazy"}
        />
        {/* first color grad */}
        <div id={"grad1Container"} className={styles.gradContainer}>
          <img src={grad1} alt={"grad1"} loading={"lazy"} />
        </div>
        {/* AND AN IDEA CAN BURN BRIGHT (s7)
         */}
        <div id={"grad2Container"} className={styles.gradContainer}>
          <img src={grad2} alt={"grad1"} loading={"lazy"} />
        </div>

        {/* IDEAS TO LIFE */}
        <div id={"cloudShiftContainer"} className={styles.cloudShiftContainer}>
          <img
            src={onlyCloudsGrad}
            className={styles.onlyCloudsGrad}
            alt={"onlyCloudsGrad"}
            loading={"lazy"}
          />
          <img
            id={"cloudShift"}
            alt={"cloudShift"}
            src={cloudShiftSource}
            className={styles.onlyClouds}
            loading={"lazy"}
          />
        </div>
        {/* WHILE GEARING UP */}
        <div
          id={"increasedCloudsContainer"}
          className={styles.increasedCloudsContainer}
        >
          <img
            id={"increasedClouds"}
            alt={"increased Clouds"}
            src={increasedClouds}
            className={styles.increasedClouds}
            loading={"lazy"}
          />
          <img
            src={increasedCloudsGrad}
            alt={"increasedCloudsGrad"}
            className={styles.increasedCloudsGrad}
            loading={"lazy"}
          />
        </div>

        {/* WHILE GEARING UP 2 */}
        <div
          id={"speckCloudsContainer"}
          className={styles.speckCloudsContainer}
        >
          <img
            className={styles.speckCloudsGrad}
            src={speckCloudsGrad}
            alt={"to bring new ideas to life"}
            loading={"lazy"}
          />
        </div>
        {/* TO BRING IDEAS TO LIFE */}
        <div
          id={"ideasToLifeContainer"}
          className={styles.ideasToLifeContainer}
        >
          <img
            className={styles.ideasToLifeGrad}
            src={ideasToLifeGrad}
            alt={"to bring new ideas to life"}
            loading={"lazy"}
          />
        </div>
        <div id={"grad3Container"} className={styles.gradContainer}>
          <img src={grad3} alt={"grad3"} loading={"lazy"} />
        </div>
        <div id={"grad4Container"} className={styles.gradContainer}>
          <img src={grad4} alt={"grad4"} loading={"lazy"} />
        </div>
        <div id={"grad5Container"} className={styles.gradContainer}>
          <img src={grad5} alt={"grad5"} loading={"lazy"} />
        </div>
      </div>
    </section>
  );
};
const getPins = () => {
  return (
    <section id={"pins"}>
      <div id={"pin1"} className={styles.pin}>
        {/* 1 */}
      </div>
      <div id={"pin2"} className={styles.pin}>
        {/* 2 */}
      </div>
      <div id={"pin3"} className={styles.pin}>
        {/* 3 */}
      </div>
      <div id={"pin4"} className={styles.pin}>
        {/* 4 */}
      </div>

      {/* Colors intensify */}
      <div id={"pin5"} className={styles.pin}>
        {/* 5 */}
      </div>
      <div id={"pin6"} className={styles.pin}>
        {/* 6 */}
      </div>

      <div id={"pin7"} className={styles.pin}>
        {/* 7 */}
      </div>
      <div id={"pin7-gap"} className={styles.pin}>
        {/* 7 -gap */}
      </div>
      {/* New image goes here - sky shifting */}
      <div id={"pin8"} className={styles.pin}>
        {/* 8 */}
      </div>
      <div id={"pin8-gap"} className={styles.pin}>
        {/* 7 -gap */}
      </div>
      {/* New image goes here - sky shifting */}

      <div id={"pin9"} className={styles.pin}>
        {/* 9 */}
      </div>

      <div id={"pin10"} className={styles.pin}>
        {generateFireWorks(1, 3)}
      </div>
      <div id={"pin11"} className={styles.pin}>
        {/* 11 */}
        {generateFireWorks(2, 4)}
      </div>
      <div id={"pin12"} className={styles.pin}>
        {generateFireWorks(3, 8)}
      </div>
      <div id={"pin13"} className={styles.pin}>
        {generateFireWorks(4, 9)}
      </div>
      <div id={"pin14"} className={classnames(styles.pin, styles.lastPin)}>
        {generateFireWorks(5, 6)}
      </div>
      <div id={"pin15"} className={classnames(styles.pin, styles.lastPin)}>
        <img
          src={initializedLogo}
          id={"logo"}
          className={styles.logo}
          loading={"lazy"}
          alt={"initialized-logo"}
        />
      </div>
    </section>
  );
};
const generateFireWorks = (groupNumber: number, amount: number) => {
  return (
    <>
      {[...Array(amount)].map((_) => (
        <section
          className={classnames(
            styles.scene,
            styles.section,
            `scene${groupNumber}`
          )}
        >
          <div id={`viewer${groupNumber}`} className={styles.viewer} />
        </section>
      ))}
    </>
  );
};
export default App;
