const cloudImg = "#cloudImg";
// UNCERTAINTY AMID UNPRECEDENTED TIMES
export const scene1Animations = (cloudTimeLine: gsap.core.Timeline) => {
  cloudTimeLine.fromTo(
    "#cloudImg",
    { scaleX: 3.5, scaleY: 3.5 },
    {
      scaleX: 1.5,
      scaleY: 1.5,
      transformOrigin: "0, 0",
      scrollTrigger: {
        trigger: "#pin1",

        start: "top center",
        end: "bottom center",
        scrub: true,
      },
      stagger: 1,
    }
  );
  // text 1 show
  cloudTimeLine.to("#text1", {
    visibility: "visible",
    scrollTrigger: {
      trigger: "#pin1",

      start: "top center",
      end: "bottom center",
      scrub: true,
    },
  });
};
// FILLED WITH ALARM AND APPREHENSION
export const scene2Animations = (cloudTimeLine: gsap.core.Timeline) => {
  cloudTimeLine.fromTo(
    cloudImg,
    {
      scaleX: 1.5,
      scaleY: 1.5,
    },
    {
      scaleX: 1,
      scaleY: 1,
      transformOrigin: "0, 0",

      scrollTrigger: {
        trigger: "#pin2",
        start: "top center",
        end: "bottom center",
        scrub: true,
      },
    }
  );
  // text 1 hide
  cloudTimeLine.to("#text1", {
    visibility: "hidden",
    scrollTrigger: {
      trigger: "#pin2",
      start: "top center",
      end: "bottom center",
      scrub: true,
    },
  });
  // text2 show
  cloudTimeLine.to("#text2", {
    visibility: "visible",
    scrollTrigger: {
      trigger: "#pin2",
      start: "top center",
      end: "bottom center",
      scrub: true,
    },
  });
};
// BUT FRICTION CREATES A SPARK
export const scene3Animations = (cloudTimeLine: gsap.core.Timeline) => {
  // text2 show
  cloudTimeLine.to("#text2", {
    visibility: "hidden",
    scrollTrigger: {
      trigger: "#pin3",
      start: "top center",
      end: "bottom center",
      scrub: true,
    },
  });
  cloudTimeLine.to("#text3", {
    visibility: "visible",
    scrollTrigger: {
      trigger: "#pin3",
      start: "top center",
      end: "bottom center",
      scrub: true,
    },
  });
};

// SPARKS FUEL IDEAS
export const scene4Animations = (cloudTimeLine: gsap.core.Timeline) => {
  const scrollTrigger = {
    trigger: "#pin4",
    start: "top+=35 center",
    end: "center center",
    scrub: true,
  };
  //  hide text 3
  cloudTimeLine.to("#text3", {
    visibility: "hidden",
    scrollTrigger: {
      ...scrollTrigger,
      start: "top center",
      end: "bottom center",
    },
  });
  // show text 4
  cloudTimeLine.to("#text4", {
    visibility: "visible",
    scrollTrigger: {
      ...scrollTrigger,
      start: "top center",
      end: "bottom center",
    },
    stagger: 1,
  });
  cloudTimeLine.to(cloudImg, {
    backgroundImage: "linear-gradient(to bottom, #79dfff, #f67e98)",
    scrollTrigger: scrollTrigger,
    stagger: 1,
  });
};

//  BLANK, COLORS INTENSIFY
export const scene5To6Animations = (cloudTimeLine: gsap.core.Timeline) => {
  const scrollTriggerPin5 = {
    trigger: "#pin5",
    start: "top center",
    end: "bottom center",
    scrub: true,
  };
  //  hide text 4
  cloudTimeLine.to("#text4", {
    visibility: "hidden",
    scrollTrigger: scrollTriggerPin5,
  });
  // first grad
  cloudTimeLine.fromTo(
    "#grad1Container",
    { visibility: "hidden" },
    {
      visibility: "visible",
      scrollTrigger: {
        trigger: "#pin5",
        start: "top center",
        end: "bottom bottom",
        scrub: true,
        endTrigger: "#pin7",
      },
    }
  );

  // Zoom in again
  cloudTimeLine.fromTo(
    cloudImg,
    {
      scaleX: 1,
      scaleY: 1,
      transformOrigin: "0, 0",
    },
    {
      scaleX: 1.3,
      scaleY: 1.3,
      backgroundImage: "unset",
      transformOrigin: "52% 39%",
      scrollTrigger: scrollTriggerPin5,
    }
  );
  // Remove gradient overlay
  cloudTimeLine.to("#overlay1", {
    visibility: "hidden",
    scrollTrigger: scrollTriggerPin5,
  });

  // PIN 6
  // Zoom in again
  cloudTimeLine.fromTo(
    cloudImg,
    {
      scaleX: 1.3,
      scaleY: 1.3,
    },
    {
      scaleX: 1.5,
      scaleY: 1.5,
      scrollTrigger: {
        trigger: "#pin6",
        start: "top center",
        end: "bottom center",
        scrub: true,
      },
    }
  );
};
// AND AN IDEA CAN BURN BRIGHT
export const scene7Animations = (cloudTimeLine: gsap.core.Timeline) => {
  cloudTimeLine.fromTo(
    cloudImg,
    {
      scaleX: 1.5,
      scaleY: 1.5,
    },
    {
      scaleX: 2,
      scaleY: 2,
      ease: "slow(0.7, 0.7, false)",
      scrollTrigger: {
        trigger: "#pin7",
        start: "=-20%",
        end: "bottom center",
        scrub: true,
      },
    }
  );

  //  LET'S GET READY FOR NEW IDEAS text
  cloudTimeLine.to("#text5", {
    visibility: "visible",
    scrollTrigger: {
      trigger: "#pin7",
      start: "top top",
      end: "bottom center",
      scrub: true,
      endTrigger: "#pin7-gap",
    },
  });
  cloudTimeLine.to("#grad1Container", {
    visibility: "hidden",
    scrollTrigger: {
      trigger: "#pin7",
      start: "top top",
      end: "top top",
      scrub: true,
    },
  });

  cloudTimeLine.fromTo(
    "#grad2Container",
    { opacity: 0.5 },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: "#pin7",
        start: "=-20%",
        end: "bottom center",
        scrub: true,
      },
    }
  );
  cloudTimeLine.fromTo(
    cloudImg,
    {
      scaleX: 2,
      scaleY: 2,
    },
    {
      scaleX: 2.4,
      scaleY: 2.4,
      ease: "slow(0.7, 0.7, false)",
      scrollTrigger: {
        trigger: "#pin7-gap",
        start: "=-50%",
        end: "bottom center",
        scrub: true,
      },
    }
  );
};
// WE'RE HERE TO STOKE THE FLAMES
export const scene8Animations = (cloudTimeLine: gsap.core.Timeline) => {
  const scrollTriggerImage: gsap.DOMTarget | ScrollTrigger.Vars = {
    trigger: "#pin8",
    start: "top center",
    end: "top center",
    scrub: true,
  };
  const cloudShift = "#cloudShift";
  // hide the old image
  cloudTimeLine.to(cloudImg, {
    // ease: "sine.out",
    opacity: "0",
    scrollTrigger: { ...scrollTriggerImage, start: "top top", end: "+=5%" },
  });
  // // bring the new image (cloud shift) forward
  cloudTimeLine.to(cloudShift, {
    opacity: 1,
    scrollTrigger: scrollTriggerImage,
  });
  // show the clouds
  cloudTimeLine.to("#cloudShiftContainer", {
    ease: "slow(0.7, 0.7, false)",
    opacity: 1,
    scrollTrigger: {
      trigger: "#pin8",
      start: "top top",
      end: "center center",
      scrub: true,
    },
  });

  cloudTimeLine.fromTo(
    cloudShift,
    { scaleX: 1, scaleY: 1 },
    {
      scaleX: 1.2,
      scaleY: 1.2,
      ease: "sine.out",

      scrollTrigger: {
        start: "=-50%",
        end: "bottom center",
        trigger: "#pin8-gap",
        scrub: true,
      },
    }
  );

  // hide text 5
  cloudTimeLine.to("#text5", {
    visibility: "hidden",
    scrollTrigger: {
      start: "top center",
      end: "bottom center",
      trigger: "#pin8",
      scrub: true,
    },
  });
  // text 6 show
  cloudTimeLine.to("#text6", {
    visibility: "visible",
    scrollTrigger: {
      start: "top center",
      end: "bottom center",
      trigger: "#pin8",
      scrub: true,
    },
  });
};
// AND WATCH THOSE IDEAS IGNITE

export const scene9Animations = (cloudTimeLine: gsap.core.Timeline) => {
  // hide text 6
  cloudTimeLine.to("#text6", {
    visibility: "hidden",
    scrollTrigger: {
      trigger: "#pin9",
      start: "top center",
      end: "top center",
      scrub: true,
    },
  });
  // show text 7
  cloudTimeLine.to("#text7", {
    visibility: "visible",
    scrollTrigger: {
      trigger: "#pin9",
      start: "top center",
      end: "bottom center",
      scrub: true,
    },
  });
  cloudTimeLine.to("#increasedCloudsContainer", {
    // visibility: "visible",
    ease: "slow(0.7, 0.7, false)",
    opacity: 1,
    scrollTrigger: {
      trigger: "#pin9",
      start: "-=30%",
      end: "center center",
      scrub: true,
    },
  });

  cloudTimeLine.fromTo(
    "#increasedClouds",
    {
      zIndex: 0,
    },
    {
      zIndex: 1,
      scrollTrigger: {
        trigger: "#pin9",
        start: "top center",
        end: "top center",
        scrub: true,
      },
    }
  );
};
// BLANK
export const scene10Animations = (cloudTimeLine: gsap.core.Timeline) => {
  //   hide text 7
  cloudTimeLine.to("#text7", {
    visibility: "hidden",
    scrollTrigger: {
      trigger: "#pin10",
      start: "top center",
      end: "top center",
      scrub: true,
    },
  });
  // hide previous image
  cloudTimeLine.fromTo(
    "#increasedCloudsGrad",
    {
      opacity: 0,
    },
    {
      opacity: 1,
      scrollTrigger: {
        trigger: "#pin10",
        start: "top center",
        end: "center center",
        scrub: true,
      },
    }
  );
  cloudTimeLine.fromTo(
    "#speckCloudsContainer",
    {
      visibility: "hidden",
    },
    {
      visibility: "visible",
      ease: "slow(0.7, 0.7, false)",
      scrollTrigger: {
        trigger: "#pin10",
        start: "top center",
        end: "bottom bottom",
        scrub: true,
      },
    }
  );

  cloudTimeLine.to("#increasedClouds", {
    transformOrigin: "32% 118% ",
    transform: " scale(1.5)",
    ease: "slow(0.7, 0.7, false)",
    scrollTrigger: {
      trigger: "#pin10",
      start: "-=20%",
      end: "bottom bottom",
      scrub: true,
    },
  });
};

// IN THE NEW YEAR AND MANY MORE TO COME (1st Time)
export const scene11Animations = (cloudTimeLine: gsap.core.Timeline) => {
  const textScrollTrigger: gsap.DOMTarget | ScrollTrigger.Vars = {
    start: "top center",
    end: "bottom center",
    trigger: "#pin11",
    scrub: true,
  };
  // show text 8
  cloudTimeLine.to("#text8", {
    visibility: "visible",
    scrollTrigger: textScrollTrigger,
  });
  //  hide text 6
  cloudTimeLine.to("#text6", {
    visibility: "hidden",
    scrollTrigger: textScrollTrigger,
  });
  // zoom on in the image even more
  cloudTimeLine.fromTo(
    "#increasedClouds",
    { transform: " scale(1.5)" },
    {
      transform: " scale(2.5)",
      ease: "power2",
      scrollTrigger: {
        trigger: "#pin11",
        start: "-=300",
        end: "top top",
        scrub: true,
      },
    }
  );
  cloudTimeLine.fromTo(
    "#ideasToLifeContainer",
    {
      visibility: "hidden",
    },
    {
      visibility: "visible",
      ease: "power2",
      scrollTrigger: {
        trigger: "#pin11",
        start: "-=300",
        end: "top top",
        scrub: true,
      },
    }
  );
  cloudTimeLine.fromTo(
    "#ideasToLifeGrad",
    {
      visibility: "hidden",
    },
    {
      visibility: "visible",
      scrollTrigger: {
        trigger: "#pin11",
        start: "top center",
        end: "bottom bottom",
        scrub: true,
      },
    }
  );
};
// IN THE NEW YEAR AND MANY MORE TO COME (2nd Time)
export const scene12Animations = (cloudTimeLine: gsap.core.Timeline) => {
  // third grad
  cloudTimeLine.fromTo(
    "#grad3Container",
    { visibility: "hidden" },
    {
      visibility: "visible",
      scrollTrigger: {
        trigger: "#pin12",
        start: "top center",
        end: "bottom bottom",
        scrub: true,
        endTrigger: "#pin13",
      },
    }
  );
};
// BLANK
export const scene13Animations = (cloudTimeLine: gsap.core.Timeline) => {
  // fourth grad
  cloudTimeLine.fromTo(
    "#grad4Container",
    { visibility: "hidden" },
    {
      visibility: "visible",
      scrollTrigger: {
        trigger: "#pin13",
        start: "top center",
        end: "bottom bottom",
        scrub: true,
        endTrigger: "#pin14",
      },
    }
  );
  // hide text 8
  cloudTimeLine.to("#text8", {
    visibility: "hidden",
    scrollTrigger: {
      start: "top center",
      end: "bottom center",
      trigger: "#pin13",
      scrub: true,
    },
  });
};
//  HAPPY NEW YEAR
export const scene14Animations = (cloudTimeLine: gsap.core.Timeline) => {
  const textScrollTrigger: gsap.DOMTarget | ScrollTrigger.Vars = {
    start: "top center",
    end: "bottom center",
    trigger: "#pin14",
    scrub: true,
  };
  // fourth grad
  cloudTimeLine.fromTo(
    "#grad5Container",
    { visibility: "hidden" },
    {
      visibility: "visible",
      scrollTrigger: textScrollTrigger,
    }
  );
  //  show text 9
  cloudTimeLine.to("#text9", {
    visibility: "visible",
    scrollTrigger: textScrollTrigger,
  });

  cloudTimeLine.to("#logo", {
    visibility: "visible",
    scrollTrigger: { ...textScrollTrigger, end: "center center" },
  });
};
