import { FC, useEffect, useState } from "react";
import styles from "./scroller.module.scss";

import controlArrow from "../../img/control-arrow.png";

import gsap from "gsap";
import classnames from "classnames";
interface IScroller {
  canStart: boolean;
}
const Scroller: FC<IScroller> = (props: IScroller): JSX.Element => {
  const { canStart } = props;
  const [controlsTimeLine, setControlsTimeLine] =
    useState<gsap.core.Timeline>(null);

  const [currentState, setCurrentState] = useState<number>(0);
  const [activeArrow, setActiveArrow] = useState<string>(null);
  // set time line on load
  useEffect(() => {
    const timeline = gsap.timeline({ paused: true, repeat: -1 });
    setControlsTimeLine(timeline);
  }, []);

  useEffect(() => {
    const scrollStop = (e: any) => {
      const prevCurrentState = currentState;
      setCurrentState(e.currentTarget.scrollY);
      // get scroll direction and then determine which class should be active
      controlsTimeLine.pause();
      controlsTimeLine.to("#controls", { opacity: 1, scale: 1 });
      if (prevCurrentState <= e.currentTarget.scrollY) {
        setActiveArrow("down");
      } else {
        setActiveArrow("up");
      }
    };
    window.addEventListener("scroll", scrollStop);
    return () => {
      window.removeEventListener("scroll", scrollStop);
    };
  }, [controlsTimeLine, currentState]);

  useEffect(() => {
    // start on scene 2
    if (controlsTimeLine && canStart) {
      controlsTimeLine.to("#controls", {
        duration: 0.5,
        opacity: 0.8,
        ease: "linear",
      });
      controlsTimeLine.to(
        "#controls",

        { duration: 1, scale: 1.2, repeat: -1, yoyo: true },
        0
      );

      controlsTimeLine.play();
    }
  }, [canStart, controlsTimeLine]);

  return (
    <div id="controls" className={styles.scrollContainer}>
      <div
        className={classnames(
          "control-arrow",
          styles.up,
          activeArrow === "up" && styles.active
        )}
        id="control-down"
      >
        <img src={controlArrow} alt={"arrow"} />
      </div>
      <div className={styles.spacer}>scroll</div>
      <div
        className={classnames(
          "control-arrow",
          styles.down,
          activeArrow === "down" && styles.active
        )}
        id="control-up"
      >
        <img src={controlArrow} alt={"arrow"} />
      </div>
    </div>
  );
};

export default Scroller;
